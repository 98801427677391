@import '../../global.scss';
@import "include-media";


.intro {
    position: relative;
    display: flex;
    gap: 1rem;
    background-color:white;
    overflow: hidden;
    min-height: calc(100vh - 70px);

    @include media("<=desktop") {
        flex-direction: column;
        align-items: center;
        gap:0;

    }

    .left {
        
        flex: 0.5;
        display:flex;
        overflow: hidden;
        align-items: center;
        justify-content: flex-end;
        
        .imgContainer {
            width: 25rem;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;

            @include media("<=desktop") {
                align-items: center;
                margin: 0 auto;
                width: 20rem;   
            }
            
            img {
                display: none;
                width: 25rem;
                height: 25rem;
                border-radius: 50%;
                z-index: 2;
                object-fit: cover;
                @include media("<=tablet") {
                    width: 20rem;
                    height: 20rem;
                }
            }
        }
    }
    .right { 
        flex: 0.5;
        z-index: 2;
        
        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 2.5rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0 auto;
        
            @include media("<=desktop") {
                max-width:90%;
                padding-left: 0;
                justify-content: flex-start;
                align-items: center;
                text-align: center;
            }
            
            h1 {
                display: inline;
                max-width: 30rem;
                font-size: 6rem;
                font-weight: 800;
                overflow: hidden;
                margin-bottom: 2rem;
                @include media("<=desktop") {
                    font-size: 3.25rem;
                    
                }
            } 

            p {
                margin-top: 1rem;
                line-height: 2;
                max-width: 30rem;
                margin-bottom:5rem;
                @include media("<=tablet") {
                    width: 100%;
                }
            }

            .buttonContainer {
                margin-top: 1.5rem;
                display: flex;
                justify-content: space-between;
                max-width: 20rem;
                gap: 1rem;
                
                .button {
                    background-color: $mainColor;
                    border-radius: 30px;
                    padding: 0.8rem 3rem;
                    color: white;
                    border: none;
                    margin-bottom: 0.5rem;
                    cursor: pointer;
                }
            }
        }

    }
    
    .wave {
        position: absolute;
        color: $mainColor;
        bottom: 0; 
        
        transform: translateY(15%);
         
    }
    
    .blob {
        position: absolute;
        width: 35rem;
        right: 20px;

    }
}