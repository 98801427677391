@import "global.scss";

.app{
    height: 100vh;

    .sections{
        width: 100%;
        min-height: calc(100vh - 70px);
        background-color: $mainColor;
        position: relative;
        top: 70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;
        

    }
}