@import '../../global.scss';
@import "include-media";


.portfolio {
    background-color: #1868ae;

    h1 {
        margin-top: 6rem;
        color: white;
        font-size: 5rem;
        @include media("<=tablet") {
            font-size: 3rem;
        }
    }

    .container {
        padding: 4rem 0;
        max-width:75%;
        display: flex;
        gap: 4rem;
        
        @include media("<=desktop") {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;
            padding:3rem 0;
        }

        @include media("<=tablet") {
            padding:2rem 0;

        }
    
    &.reverse {
        flex-direction: row-reverse;
        
        @include media("<=desktop") {
            flex-direction: column;
        }

        .imageContainer {

            display: flex;
            justify-content: flex-end;


            @include media("<=desktop") {
                justify-content: center;
                align-items: center;
            }
        }


    }
        .youtubeVideo {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        }

        .imageContainer {
            max-width: 40rem;
            display: flex;
            border-radius: 5px;
            @include media("<=desktop") {
                justify-content: center;
                align-items: center;
    
            }
            
            .image {
                width: 100%;
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
                object-fit:contain;
            }
        }

        .textContainer {
            color: #fff;
            background-color: #1868ae;
            display: flex;
            max-width: 33ch;
            flex-direction: column;
            justify-content: center;
            @include media("<=desktop") {
                text-align: center;
                align-items: center;
            }

            h2 {
                font-size: 3rem;
                line-height: 1.1;
                padding-bottom: 1rem;
                @include media("<=tablet") {
                    font-size: 2.5rem;
                }

            }




            p {
                font-size: 0.9rem;
                line-height: 2;
                margin-bottom: 1rem;
       
            }


            .linksContainer {
                margin-top: 0.5rem;
                display: flex;
                align-items: center;
                gap: 1.5rem;
                button {
                    margin-top: 0.75rem;
                    padding: 0.5rem 1rem;
                    margin-left: 1rem;
                    cursor:pointer;
                    border-radius: 20px;
                    text-decoration: none;
                    border: none;
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    transition: all 0.3 ease-in-out;
                    background: #fff;
                    font-weight: bold;

                    &:hover {
                        color: gray;
                    }
                }

                .icon {
                    transition: 0.3s ease;
                    border-radius: 50px;
                    width: 2rem;
                    margin-top: 1rem;
                    color: white;
                    &:hover {
                        background-color: white;
                    
                        cursor: pointer;
    
                    }
                }   
            }
        }
    }
    
}   