$mainColor: #1868ae;
$lightblue: #DFF1FF;
$mauve: #d9a5b3;
$sapphire: #1868ae;
$powderBlue: #c6d7eb;
$lightbrown: #DBA11C;

$mobileWidth: 768px;
$mediumWidth: 1024px;
@mixin mobile {
    @media (max-width: #{$mobileWidth}) {
        @content
    }
}

