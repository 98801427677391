@import "../../global.scss";
@import "include-media";

.about{
    position: relative;
    background-color: #fff;
    display: flex;
    
    overflow:hidden;
    .container {
        z-index: 2;
        display: flex;
        flex-direction: row;
        padding:8rem 0 10rem 0;
        flex-wrap: wrap;

        margin:0 auto;
        gap: 2rem;
        
        @include media("<=1200px") {
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 6rem 0;
            gap: 1rem;
        }

        .textContainer {
            display: flex;
            flex-direction: column;
            @include media("<=1200px") {
                justify-content: center;
                align-items: center;
            }

            .aboutMe {

                @include media(">=1200px") {
                    
                    color: white;
                }
            }

            .hobbies {
            
                @include media(">=1200px") {
                    
                    margin-top: 7rem;
                }
            }

            .skills {

                @include media(">=1200px") {
                    margin-top: 3.5rem;
                }
            }

            h2 {
                font-size: 4.5rem;
                z-index: 100;
                @include media("<=phone") {
                    font-size: 3rem;
                }

            }
            .paragraphContainer {
                background-color: #DFF1FF;
                max-width: 20ch;
                box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
                padding: 1rem;
                border-radius: 30px;

                
                @include media("<=phone") {
                    margin: 0 1rem;
                }
                .aboutParagraph {
                    font-size: 1rem;
                }

                p {
    
                    line-height: 2;
                    font-size: 0.9rem;
                    font-weight: 400;
    
                }
            }

            .skillsContainer {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                background-color: #DFF1FF;
                align-items: center;
                justify-content:center;
                max-width: 20ch;
                padding: 1rem;
                border-radius: 30px;
                min-width: 10rem;
                box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

                @include media("<=desktop") {
                    max-width: 50%;
                }

                .skillContainer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-right: 1rem;
                    width: 4.8rem;
                    h5 {
                        font-size: 0.9rem;
                    }

                    img {
                        margin-top: 0.75rem;
                        width: 3rem;
                        

                    }

                }
            }

        }
    }
    .wave {
        
        position: absolute;
        top:0;
        transform: scale(1.2) translateY(5%); 
    }

    .wave2 {
        
        position: absolute;
        bottom:0;
        transform: scale(1.2) translateY(15%); 
    }


}