@import '../../global.scss';

.topbar {
    position: fixed;
    width: 100%;
    height: 70px;
    background-color: white;
    color: $mainColor;
    top: 0;
    z-index: 9999;
    transition: all 1s ease-in-out;



    .wrapper {
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
    
    
            .logo { 
                font-size: 30px;
                font-weight: 700;
                text-decoration: none;
                color: inherit;
                margin-right: 40px;
            }
    
            .itemContainer {
                display: flex;
                margin-left: 40px;
                
                @include mobile {
                    display: none;
                }

                .icon {
                    
                    transition: 0.3s ease;
                    color: $mainColor;
                    border-radius: 50px;
                    padding: 2px ;
                    &:hover {
                        background-color: #e4e9f1;
                    
                        cursor: pointer;
    
                    }
                    .resumeImage {
                        border-radius: 10px;
                        width: 2rem;
                        
                    }
                }
    
            }
        }
    

        .right {
            .hamburger {
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                
                span {
                    width: 100%;
                    height: 2.5px;
                    background-color: $mainColor;
                    transform-origin: left;
                    transition: all 2s ease; 
                }
            }
        }
    }


    &.active {
        background-color: $mainColor;
        color: white;

        .icon {
            display: none;
        }

        .hamburger {
            span {
                &:first-child{ 
                    background-color: white;
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child{ 
                    background-color: white;
                    transform: rotate(-45deg);
                }
            }
        }
    }
    
}