@import "../../global.scss";
@import "include-media";

.contact {
    position: relative;
    background-color:$mainColor;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 70px);
    padding:7rem;

    @include media("<=tablet") {
        padding: 3rem 1.5rem;
    }

    .container {
        background-color:#619BE6;
        padding: 2.5rem;
        border-radius: 20px;
        width: 90%;
        max-width: 30rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 5px 15px rgba(0,0,0,0.2);

        h1 {
            margin-bottom: 1rem;
            font-size: 3rem;
            @include media("<=tablet") {
                font-size: 2.5rem;
            }
        }

        .contact_form {
            width: 100%;
            display: flex;
            flex-direction: column;

            input, textarea {
                padding: 1rem 1.5rem;
                margin: 1rem;
                border-radius: 30px;
                border: none;
                outline: none;
            }

            textarea {
                min-height: 9rem;
                border-radius: 15px;
            }

            .error {
                font-size: 1.2rem;
                font-weight: bold;
                margin-left: 1rem;
            }

            .submit_button {
                color: white;
                background-color: #DBA11C;
                cursor: pointer;
                font-size: 1rem;

                box-shadow: 0 5px 15px rgba(0,0,0,0.2);
            }


            
        }   
    }
}